.App {
  font-family: sans-serif;
  text-align: center;
}

.blockEvents {
  pointer-events: none;
}

.app-layout {
  margin: auto;
  max-width: 1200px;
  padding: 0 20px;
}

.large-spinner-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-button {
  font-size: 24px;
  color: #fff;
}

.speaker-tag {
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  padding: 3px 8px;
  cursor: pointer;
  user-select: none;
  color: #1c1c1c !important;
}

.speaker-tag-selected {
  border-color: #1890ff !important;
  border-width: 2px;
}

.speaker-tag-unselected {
  border-color: #1890ff40 !important;
  border-width: 2px;
}

.speaker-tag-close-icon {
  color: #1c1c1c !important;
}

.toggle-button {
  padding: 2px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}
