.fancy-border {
  border: 5px solid transparent;
  padding: 15px;
  border-image: linear-gradient(to right, #f06, #a1c4fd);
  border-image-slice: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition:
    box-shadow 0.3s ease,
    transform 0.3s ease;
  margin-top: 10px;
}

.fancy-border:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}

.progress-width-50 {
  width: 50%;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.color-dark-blue {
  color: var(--dark-blue);
}

.color-light-blue {
  color: var(--light-blue);
}

.background-color-memoized {
  background-color: var(--memoized-color);
}

.validate-button {
  background-color: #4caf50;
  border-color: #4caf50;
  color: white;
  cursor: pointer;
}

.validate-button:disabled {
  background-color: grey;
  border-color: gray;
  cursor: forbidden;
  color: white;
}

.reject-button {
  background-color: red;
  border-color: red;
  color: white;
}

.button-reject {
  background-color: red;
  border-color: red;
  color: white;
}

.region-controller {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
}
